*{
    /* margin: 0;
    padding: 0; */
    box-sizing: border-box;
}
.accordion{
    width: 800px;
    margin: 50px auto 30px;
    position: relative;
    background: rgb(255, 255, 255);
    border-radius: 24px;
    border: 1px solid transparent;
    padding: 20px;
    z-index: 1;
    box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
}

.accordion .panel{
    border-radius: 20px;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgb(247, 248, 250);
    padding: 20px;
    margin: 5px 0;
}
.accordion .panel__head h2{
    margin-top: 0;
    margin-bottom: 0;
}
.accordion .panel[aria-expanded="true"]{
    background: #6461ae;
    color: #fff;
}
.accordion .panel[aria-expanded="true"] .panel__head h2{
    color: #ffffff;
}
.accordion .panel__content p{
    margin-bottom: 0;
}